<template>
  <div>
    <TabHeaders>
      Resolve Dispute
      <template #text>
        Chat with the other party and resolve you dispute here, you can also
        request for a mediator to resolve you case
      </template>
    </TabHeaders>

    <a-drawer
      title="Information about Dispute"
      placement="right"
      :width="width > '640' ? 420 : 320"
      :visible="disputeDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="disputeDrawerVisible = false"
    >
      <div>
        <h2 class="text-base">Title</h2>
        <p class="text-sm text-gray-600">
          {{ disputeData.title }}
        </p>
      </div>
      <div>
        <h2 class="text-base">Description</h2>
        <p class="text-sm text-gray-600">
          {{ disputeData.description }}
        </p>
      </div>
      <div>
        <h2 class="text-base">Cost implication</h2>
        <p class="text-sm text-gray-600">
          {{ disputeData.costImplication }}
        </p>
      </div>
      <div>
        <h2 class="text-base">Download Files</h2>
        <a
          v-for="(file, i) in disputeData.files"
          :key="i"
          :href="file"
          target="_blank"
          class="text-sm text-gray-600"
        >
          Download
        </a>
      </div>

      <div>
        <div class="steps-action">
          <a-button type="primary" @click="disputeDrawerVisible = false">
            Ok
          </a-button>
        </div>
      </div>
    </a-drawer>

    <a-modal
      v-model="showMessageAboutConfirmation"
      title="Resolve Dispute"
      cancel-text="Reject"
      ok-text="Accept"
      :mask-closable="false"
      :closable="false"
      :keyboard="false"
      @ok="otherPartyResolve('resolved')"
      @cancel="otherPartyResolve('unresolved')"
    >
      <div>
        <p>The other party choose to resolve this dispute</p>
        <p>Enter any comments below</p>
        <div>
          <a-textarea v-model="messagesForDecline" auto-size placeholder="" />
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="showSelectMediatorModal"
      ok-text="Accept"
      :mask-closable="false"
      :closable="false"
      :keyboard="false"
      title="Select Mediator"
      @ok="responseToMediatorRequest('accepted')"
      @cancel="responseToMediatorRequest('rejected')"
    >
      <div>
        <p>{{ messageToShow }}</p>
        <div class="mt-4">
          <div v-for="option in mediatorOptions" :key="option._id">
            <label
              :for="option._id"
              :class="` ${
                selectedMediators.includes(option._id) ? 'bg-green-100' : ''
              }
cursor-pointer py-2 px-2 rounded block w-full`"
            >
              <div class="flex items-center justify-start">
                <div class="flex items-center">
                  <div class="mr-4">
                    <img class="rounded-full w-8 h-8" :src="option.avatar" />
                  </div>
                  <div>
                    <h3 class="font-medium text-sm mb-0">
                      {{ option.firstName }} {{ option.lastName }}
                    </h3>
                    <router-link
                      target="_blank"
                      :to="`/${option.lastName.toString()}-${option.firstName.toString()}/${
                        option._id
                      }`"
                      class="text-xs text-gray-500 my-0"
                    >
                      view Profile
                    </router-link>
                  </div>
                </div>
              </div>
            </label>
            <input
              :id="option._id"
              v-model="selectedMediators"
              :value="option._id"
              name="mediators"
              type="checkbox"
              class="hidden"
            />
          </div>
          <div class="mt-4 text-red-400 text-xs">
            NB: Clicking on cancel with stop this request for a mediator
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="showResolveMessageModal"
      title="Enter Resolve notes"
      @ok="resolveDispute"
    >
      <div>
        <p>
          You are about to mark this dispute has resolve, the other party will
          receive a notification to agree, if they do this case is closed
        </p>
        <div>
          <p>Enter any comments below</p>
          <a-textarea v-model="initiatorComment" auto-size> </a-textarea>
        </div>
      </div>
    </a-modal>

    <div v-if="disputeData === {}">
      <a-spin />
    </div>
    <div
      v-else
      id="chat-area"
      class="grid-ish-chat bg-white rounded-md shadow-md h-screen"
    >
      <div
        class="
          chat-header
          py-3
          px-2
          border-b border-gray-400
          w-full
          flex
          items-start
          justify-between
        "
      >
        <div class="flex items-center justify-start">
          <!-- <img class="w-8 h-8 rounded-full mr-6" /> -->
          <div>
            <p class="mb-0 text-sm font-bold">
              {{ disputeData.respondentName }}
            </p>
            <p class="text-xs font-light">{{ disputeData.respondentEmail }}</p>
          </div>
        </div>
        <button class="text-xs p-2 bg-gray-800 text-white" @click="getMessages">
          refresh
        </button>
        <a-button type="" @click="disputeDrawerVisible = true"
          >Dispute Info</a-button
        >
      </div>
      <div class="chat-body h-full w-full">
        <div ref="chatForm" class="flex flex-col w-100 py-3 px-2">
          <div
            v-for="msg in messages"
            :key="msg._id"
            :class="`bg-gray-300 text-gray-800 text-sm rounded-full my-1 py-2 px-5 flex flex-col w-8/12 ${
              msg.sender === user._id
                ? 'text-right self-end'
                : 'text-left self-start'
            } `"
          >
            <span class="text-right text-xs text-gray-700 text-right"
              >{{ new Date(msg.createdAt).toDateString() }} |
              {{ new Date(msg.createdAt).toTimeString().slice(0, 5) }}</span
            >
            <span>{{ msg.message }}</span>
          </div>
        </div>
      </div>
      <div class="chat-form w-full">
        <div class="chat-option w-full">
          <div class="flex items-center justify-between px-3 py-3">
            <a-button
              :loading="resolvingDispute"
              :disabled="
                aPartyHasInitiateResolve &&
                initiatedResolveData.initiator._id === user._id
              "
              class="text-medium bg-gray-700 text-white py-2 px-4 rounded"
              @click="showResolveMessageModal = true"
            >
              Resolve
            </a-button>
            <a-button
              type="primary"
              class="text-medium text-white bg-primary-dark py-2 px-4 rounded"
              :loading="requestingForMediator"
              @click="requestForMediator"
            >
              Request Mediator
            </a-button>
          </div>
        </div>
        <div class="chat-message border">
          <div class="flex">
            <form class="w-full" @submit.prevent="sendMessage">
              <input
                v-model="message"
                placeholder="Reply here"
                class="
                  w-full
                  border-primary border
                  py-2
                  px-4
                  focus:outline-none
                "
              />
            </form>
            <a-button
              :disabled="message === ''"
              :type="message === '' ? 'secondary' : 'primary'"
              class="text-white bg-primary-dark font-medium px-3"
              @click.prevent="sendMessage"
            >
              Send
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabHeaders from '../../../components/typography/TabHeaders.vue'
import disputeApi from '../../../api/dispute'
import messageApi from '../../../api/message'

export default {
  name: 'UserManagement',
  components: { TabHeaders },
  layout: 'userdashboard',
  data() {
    return {
      disputeId: '',
      userState: '',
      disputeDrawerVisible: false,
      message: '',
      messages: [],
      disputeData: {},
      resolvingDispute: false,
      showResolveMessageModal: false,
      requestingForMediator: false,
      selectedMediators: [],
      resolveMessage: '',
      showSelectMediatorModal: false,
      aPartyHasInitiateResolve: false,
      aPartyHasInitiateRequestForMediator: false,
      showMessageAboutConfirmation: false,
      messagesForDecline: '',
      initiatorComment: '',
      initiatedResolveData: {
        initiator: {},
        respondent: {},
      },
      mediatorOptions: [],
      initiatedMediatorData: {
        initiator: {},
        respondent: {},
      },
      messageToShow: '',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width
    },
  },
  watch: {
    selectedMediators(newValue, oldValue) {
      if (newValue.length === 4) {
        this.selectedMediators = oldValue
        this.$notification.info({
          message: '',
          description: "You can't select more that 3 lawyers",
        })
      }
    },
  },
  created() {
    this.disputeId = this.$route.params.disputeId
    this.userState = this.$route.query.state
    this.getDispute()
    this.getMessages()
    this.getCurrentResolution()
    this.getCurrentMediator()
  },
  methods: {
    async otherPartyResolve(status) {
      const req = await disputeApi(this.axios).updateCurrentDisputeResolution({
        disputeId: this.disputeId,
        respondentComment: this.messagesForDecline,
        status,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.message,
        })
        return
      }
      this.getCurrentResolution()
      this.$notification.success({
        message: 'Success',
        description: req.message,
      })
    },
    async responseToMediatorRequest(status) {
      const req = await disputeApi(this.axios).updateCurrentMediatorStatus({
        disputeId: this.initiatedMediatorData._id,
        status,
        selections: this.selectedMediators,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Sorry, an error occurred',
          description: req.message,
        })
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message,
      })
      this.showSelectMediatorModal = false
    },
    scrollToEnd() {
      setTimeout(() => {
        this.$refs.chatForm.scrollTop = this.$refs.chatForm.scrollHeight
      }, 500)
    },
    async getDispute() {
      const req = await disputeApi(this.axios).get({
        disputeId: this.disputeId,
        params: {},
      })
      this.disputeData = req.data
    },
    async getMessages() {
      const req = await messageApi(this.axios).get(
        { type: 'all' },
        {
          dispute: this.disputeId,
          limit: 0,
        }
      )
      this.messages = req.data.data.reverse()
      // this.scrollToEnd()
    },
    async sendMessage() {
      if (this.disputeData.respondent === null) {
        this.$notification.info({
          message: "Can't start a conversation",
          description:
            'You cannot start a conversation with this user because this user is yet to register',
        })
        return
      }

      const req = await messageApi(this.axios).send({
        message: this.message,
        recipientId:
          this.userState === 'respondent'
            ? this.disputeData.complainant
            : this.disputeData.respondent,
        disputeId: this.disputeId,
        title: `Message from ${this.user.firstName} to ${this.disputeData.respondentName}`,
        sendTo: 'user',
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error sending message',
          description: req.messages,
        })
        return
      }
      this.message = ''
      this.getMessages()
      // this.scrollToEnd()
    },
    async getCurrentResolution() {
      const req = await disputeApi(this.axios).currentDisputeResolution({
        disputeId: this.disputeId,
      })
      if (req.error) {
        // Assume there is no resolve
        this.aPartyHasInitiateResolve = false
        return
      }
      this.aPartyHasInitiateResolve = true
      this.initiatedResolveData = req.data
      if (this.initiatedResolveData.respondent._id === this.user._id) {
        this.showMessageAboutConfirmation = true
      }
    },
    async resolveDispute() {
      if (this.aPartyHasInitiateResolve) {
        this.showMessageAboutConfirmation = true
        return
      }
      this.resolvingDispute = true
      const req = await disputeApi(this.axios).resolveDispute({
        status: 'resolved',
        initiatorComment: this.initiatorComment,
        disputeId: this.disputeId,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.message,
        })
        this.showResolveMessageModal = false
        this.resolveMessage = ''
        this.resolvingDispute = false
        return
      }
      this.getCurrentResolution()
      this.$notification.success({
        message: 'Message sent',
        description: 'Resolve sent to the other party',
      })
      this.showResolveMessageModal = false
      this.resolveMessage = ''
      this.resolvingDispute = false
    },
    async requestForMediator() {
      this.requestingForMediator = true
      const req = await disputeApi(this.axios).requestMediator({
        disputeId: this.disputeId,
      })
      if (req.error) {
        // this.$notification.error({
        //   message: 'Unable to get mediators',
        //   description: req.message,
        // })
        this.requestingForMediator = false
        return
      }
      this.getCurrentMediator()
      this.requestingForMediator = false
    },
    async getCurrentMediator() {
      const req = await disputeApi(this.axios).currentMediatorStatus({
        disputeId: this.disputeId,
      })
      if (req.error) {
        // this.$notification.error({
        //   message: 'Unable to get mediators',
        //   description: req.message,
        // })
        return
      }
      this.initiatedMediatorData = req.data
      if (this.initiatedMediatorData.initiator._id === this.user._id) {
        this.messageToShow =
          'You have requested for the need of a mediator in this dispute, kindly select any 3 mediator of your choice, the other party will select all'
      } else {
        this.messageToShow =
          'The other party has requested for a mediator, kindly select any 3 mediator of your choice'
      }
      if (
        this.initiatedMediatorData.status === 'pending' ||
        this.initiatedMediatorData.status === 'initiated'
      ) {
        this.showSelectMediatorModal = true
        this.aPartyHasInitiateRequestForMediator = true
        this.mediatorOptions = req.data.options
      }
    },
  },
}
</script>

<style>
.grid-ish-chat {
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  grid-template-areas: 'header' 'body' 'message';
}
.chat-header {
  grid-area: 'header';
}
.chat-body {
  grid-area: 'body';
  overflow-y: scroll;
}
.chat-form {
  grid-area: 'message';
}
</style>
